import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trixEditor"]

  onEditorBlur() {
    const editor = this.trixEditorTarget.editor
    this.lastCursorPosition = editor.getSelectedRange()
  }

  insertSmartField(event) {
    const dropdown = event.target.closest("[data-controller='dropdown']")
    dropdown.dataset.dropdownOpenValue = false

    const editor = this.trixEditorTarget.editor
    const text = event.target.dataset.value.trim()
    editor.setSelectedRange(this.lastCursorPosition || [0, 0])
    editor.insertString(text)

    this.lastCursorPosition = null
  }
}
