import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "buttonText"];

  toggledOption() {
    const selected = this.checkboxTargets
      .filter(cb => cb.checked)
      .map(cb => this.element.querySelector(`label[for="${cb.id}"]`).innerText)

    if (selected.length === 0) {
      this.buttonTextTarget.innerText = "Choose options"
    } else if (selected.length === this.checkboxTargets.length) {
      this.buttonTextTarget.innerText = "All"
    } else if (selected.length > 2) {
      this.buttonTextTarget.innerText = selected.slice(0, 2).join(", ") + `, and ${selected.length - 2} more`
    } else {
      this.buttonTextTarget.innerText = selected.join(", ")
    }
  }
}
