import { Controller } from "@hotwired/stimulus"

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default class extends Controller {
  static targets = ["cashPrice"]

  connect() {
    this.lastCashPrice = Number(this.cashPriceTarget.value)
  }

  calculateBilling = debounce(() => {
    const cashPrice = Number(this.cashPriceTarget.value)
    if (cashPrice !== this.lastCashPrice) {
      this.lastCashPrice = cashPrice

      const turboFrame = document.getElementById("billing_preview")
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('cash_price', cashPrice)
      const url = `${window.location.href.split('?')[0]}?${searchParams.toString()}`
      turboFrame.src = url
    }
  }, 100)
}
