import { get, patch } from "api"

export const getSiteMap = async (siteMapId: string) => {
  return get(`/company_admin/site_maps/${siteMapId}`)
}

export const getCustomerSiteMap = async (siteMapId: string) => {
  return get(`/site-maps/${siteMapId}`)
}

interface CustomerType {
  id: string
  name: string
  balance: number
  pastDue: number
}

export interface UnitType {
  occupancyStatus: "past_due" | "vacant" | "occupied"
  customer?: CustomerType
  price: number
  disabled: boolean
  locked: boolean
  auction: boolean
  priceDuration: string
  scheduledMoveOut?: string,
  lockCode?: string,
  auctionDate?: string
}
interface GetSiteMapUnitStatusesResponse {
  units: {
    [key: string]: UnitType
  }
}
export const getSiteMapUnitStatuses = async (siteMapId: string) => {
  return get<GetSiteMapUnitStatusesResponse>(
    `/company_admin/site_maps/${siteMapId}/unit_statuses`
  )
}

export const getCustomerSiteMapUnitStatuses = async (siteMapId: string) => {
  return get<GetSiteMapUnitStatusesResponse>(
    `/site-maps/${siteMapId}/unit_statuses`
  )
}

export const updateSiteMapRow = async (
  siteMapId: string,
  storageUnitRowId: string,
  position: string
) => {
  return patch(
    `/company_admin/site_maps/${siteMapId}/site_map_storage_unit_rows/${storageUnitRowId}`,
    { position }
  )
}
