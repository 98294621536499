import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit = (e) => {
    this.element.querySelectorAll("[type=submit]").forEach((element) => {
      element.disabled = true
      element.innerHTML = `
        <span class="flex items-center gap-2 ">
          <svg id="loading-spinner" class="size-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle style="opacity: 0.25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
            <path style="opacity: 0.75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          Loading...
        </span>
      `
    })
  }

  connect = () => {
    const existingActions = this.element.dataset.action || ""
    this.element.dataset.action = [existingActions, "submit->disable-on-submit#submit"].join(" ")
  }
}
